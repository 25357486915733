.bg-evrybo {
  background-color: #5081F5;
}

.bg-freehand {
  background-color: #ff0063;
}

.bg-dribbble {
  background-color: #EA4C89;
}

.bg-behance {
  background-color: #0057FF;
}

.bg-instagram {
  background-color: black;
}


.bg-bounce {
  background-color: #5081F5;
}

.create-btn {
  bottom: 32px;
  right:120px;
}

.img-container {
  z-index: 1;
}

.dot {
  position: absolute;
  width: 32px;
  height: 32px;
  z-index: 16;
  background-color: rgba(73, 198, 255, 0.8);
  border-radius: 50%;
  border: 2px solid white;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}

.side-bar-container {
  min-width: 360px;
}

.dot-grid {
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 2px, rgb(255, 255, 255) 2px, rgb(255, 255, 255) 20px), linear-gradient(to right, rgb(200, 195, 199), rgb(200, 195, 199) 2px, rgb(255, 255, 255) 2px, rgb(255, 255, 255) 20px);
  background-position: 389px -2px;
  background-size: 20px 20px;
}

.tippy-popper .tippy-content{
  padding: 0;
}

.popover-comment {
  min-height: 48px;
}

nav.nav-bar {
  z-index: 9999;
}

.tippy-popper {
  z-index: 9998 !important;
}

.Toastify__toast-body {
  color: rgb(33, 33, 33);
}

@media screen and (max-width:1280px) {
  .create-btn {
    right: 32px;
  }
}